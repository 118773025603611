<template>
  <div class="page">
    <div class="headBgc"></div>
    <div class="navHead">
      <div class="nav">
        <div class="navName">意向订单表</div>
        <img
          :src="$ossRes('/common/arrow.png')"
          alt=""
          class="shareIcon"
          @click="back"
        />
      </div>
    </div>
    <div class="content">
      <div class="infomation">
        <img class="content-top-img" :src="$ossRes('/common/overlay.png')" alt="" srcset="">
        <div class="styleInfo">
          <img :src="$ossRes('/common/name.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>姓名</p>
            <p class="styleInfo-p">{{ form.user_name }}</p>
          </div>
        </div>
        <div class="styleInfo">
          <img :src="$ossRes('/common/tel.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>手机号</p>
            <p class="styleInfo-p">{{ form.phone }}</p>
          </div>
        </div>
        <div class="styleInfo">
          <img :src="$ossRes('/common/company.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>公司/单位</p>
            <p class="styleInfo-p">{{ form.company }}</p>
          </div>
        </div>
        <div class="styleInfo">
          <img :src="$ossRes('/common/job.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>职务</p>
            <p class="styleInfo-p">{{ form.position }}</p>
          </div>
        </div>
        <div class="styleInfo">
          <img :src="$ossRes('/common/version.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>版本</p>
            <p class="red styleInfo-p">{{ form.template?.name }}</p>
          </div>
        </div>
        <div class="styleInfo">
          <img :src="$ossRes('/common/model.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>模板</p>
            <p class="styleInfo-p">{{ form.template?.text }}</p>
          </div>
        </div>
        <div class="styleFooter" v-if="form.template?.type !='3'">
          <img :src="form.template?.img" alt="" class="footerImg" />
        </div>
      </div>
      <div class="desLine">
        <div class="line"></div>
        <p class="lineText">面积：</p>
        <p class="lineOther">{{ form.template?.area }}m²</p>
      </div>
      <div class="checkList">
        <div class="topCheck">
          <p>功能模块</p>
          <p class="middlePrice">价格(元)</p>
          <p>选择</p>
        </div>
        <div class="checkCotent">
          <!-- <van-checkbox-group v-model="result"> -->
            <div
              class="styleCheck"
              v-for="(item, index) in checkList"
              :key="index"
            >
              <div class="require">
                <img
                  :src="$ossRes('/common/require.png')"
                  alt=""
                  class="requireImg"
                  v-if="item.require"
                />
              </div>
              <div class="right">
                <div class="title">{{ item.title }}</div>
                <div class="price">{{ item.price }}</div>
                <van-radio-group v-model="item.checked" :disabled="!item.checked">
                  <van-radio
                    :name="item.checked ? item.checked : item.name"
                    icon-size="16px"
                    checked-color="#ee0a24"
                  ></van-radio>
                </van-radio-group>
              </div>
            </div>
          <!-- </van-checkbox-group> -->
          <div class="total">合计 (含税)</div>
          <div class="totalPrice">{{ price }}</div>
        </div>
      </div>
      <div class="footerList">
        <div class="topCheck"></div>
        <div class="footerContent">
          <p class="footerTitle">项目名称</p>
          <div class="projectName">{{ order_name }}</div>
          <p class="footerTitle">分区命名: {{order_temp.template.title}}</p>
          <div class="partitionTitle">
            <div
              class="partition"
              v-for="(item, index) in partitionList"
              :key="index"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footerBtn">
      <div class="sureClick" @click="submit">提交意向</div>
      <div class="advise" @click="share">分享</div>
    </div>
    <feed-back
      msg="报价反馈"
      :feedDate="feedDate"
      :show="showFeedBack"
      @closeFeed="closeFeed"
    ></feed-back>
    <pop-success
      tips="将有专属商务与您联系，请保持手机畅通"
      :show="show_pop_success"
      :showbtn="false"
      @close="close"
    ></pop-success>
    <share-wx :show="showShareWx" @close="closeShareWx"></share-wx>
  </div>
</template>
<script>
import feedBack from "@/components/feedBack.vue";
import popSuccess from "@/components/popSuccess.vue";
import { get, post } from "@/assets/js/http.js";
import shareWx from "@/components/shareWx.vue";
export default {
  components: { feedBack, popSuccess, shareWx },
  data() {
    return {
      feedDate: {},
      form: {},
      userId: "",
      partitionList: [],
      checkList: [],
      function_inside:[],
      function_outside: [],
      showFeedBack: false,
      show_pop_success: false,
      isfrom: 0,
      showShareWx: false,
      order_name: '',
      order_temp: null,
      cango: true,
      price: 0
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.getList()
  },
  methods: {
    share(){
      // todo 添加wxshare({...})
      this.showShareWx = true;
    },
    closeShareWx() {
      this.showShareWx = false;
    },
    back() {
      this.$router.back();
    },
    getList(){
      let order_temp = JSON.parse(sessionStorage.getItem('order_temp'))
      let partition = JSON.parse(sessionStorage.getItem('partition'))
      let function_inside = JSON.parse(sessionStorage.getItem('function_inside'))
      let function_outside = JSON.parse(sessionStorage.getItem('function_outside'))
      this.form = {
        user_name: order_temp.user_name,
        phone: order_temp.phone,
        company: order_temp.company,
        position: order_temp.position,
        template: order_temp.template,
        order_name: order_temp.order_name,
      }
      this.order_temp = order_temp
      this.partitionList = partition
      this.order_name = sessionStorage.getItem('order_name')
      function_inside.map(item => {
        item.checked = Number(item.checked)
        item.require = Number(item.require)
      })
      function_outside.map(item => {
        item.checked = Number(item.checked)
        item.require = Number(item.require)
      })
      this.checkList = [...function_inside, ...function_outside]
      let price = 0;
        if(order_temp.template.type !== 3)
            function_inside.map(item => {
                price += item.price
            })
        function_outside.map(item => {
            price += item.price
        })
        price +=  order_temp.template.type == 3 ? order_temp.template.per_price*Number(order_temp.template.area) : order_temp.template.base_price
        this.price = price
        this.function_inside = function_inside
        this.function_outside = function_outside
    },
    submit() {
      // 定制版: 每平米价格*面积+套餐外价格
      // 非定制版: 基础价格+套餐内+套餐外
      let params = {
          user_id: this.$getUserInfo().id,
          user_name: this.order_temp.user_name,
          phone: this.order_temp.phone,
          position: this.order_temp.position,
          company: this.order_temp.company,
          order_name: this.order_name,
          template: this.order_temp.template,
          function_inside: this.function_inside,
          function_outside: this.function_outside,
          partition: this.partitionList,
          type: this.order_temp.template.type
      }
      if(!this.cango) return
      this.cango = false

      let that = this
      this.$http.post('/sale/h5/addOrder', params).then(res => {
          if(res.code == 200){
              // that.$toast.success('提交成功')
              this.show_pop_success = true
              setTimeout(() => {
                  // that.$router.push({
                  //     name: "Home",
                  //     query: {
                  //       userId: that.$route.query.userId,
                  //     },
                  // })
                  that.cango = true
              }, 2000);
          }else{
              that.cango = true
              that.$toast.fail(res.msg)
          }
      }).catch(err => {
          that.cango = true
          that.$toast.fail('请联系管理员')
      })
    },
    initiator() {
      this.feedDate = {
        user_id: this.userId,
        user_name: this.userInfo.user_name, // this.form.customer?.user_name,
        phone: this.userInfo.phone, // this.form.customer?.phone,
        company: this.userInfo.company, // this.form.customer?.company,
      };
      this.showFeedBack = true;
    },
    close(){
      this.show_pop_success = false
      sessionStorage.clear()
      this.$router.push({
        name: "Home",
        query: {
          userId: this.$route.query.userId,
        },
      })
    },
    closeFeed() {
      this.showFeedBack = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  padding-bottom: 120px;
  background-color: #f2f2f2;
  // overflow-x: hidden;
  .headBgc {
    position: absolute;
    top: 0;
    width: 100%;
    height: 478px;
    // background-image: url("~@/assets/image/caseBgc.png");
    background-image:url("@{PublicEnv}/common/caseBgc.png");
    background-size: 100% 100%;
    z-index: 0;
  }
  .navHead {
    position: relative;
    width: 100%;
    height: 96px;
    z-index: 10;
    .nav {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 48px;
      box-sizing: border-box;
      height: 96px;
      line-height: 96px;
      text-align: center;
      .navName {
        font-size: 36px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .shareIcon {
        position: absolute;
        left: 48px;
        bottom: 23px;
        width: 50px;
        height: 54px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 15;
      position: relative;
      .content-top-img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 654px;
        margin: auto;
      }
    .infomation {
      width: 686px;
      // height: 1026px;
      background: #fcfcfc;
      box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.102);
      border-radius: 16px;
      opacity: 1;
      padding: 32px 43px 58px;
      box-sizing: border-box;
      z-index: 1;
      margin-top: 34px;
      .styleInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 32px;
        .infoImg {
          width: 40px;
          height: 40px;
        }
        .infoInput {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 547px;
          height: 72px;
          line-height: 45px;
          font-size: 28px;
          font-weight: 400;
          color: #333333;
          border-bottom: 1px solid #cccccc;
          .styleInfo-p {
            // flex: 1;
            height: 72px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 10px;
            width: 340px;
            text-align: left;
            word-break: break-all;
          }
          .styleInfo-com{
            min-height: 72px;
            margin-left: 10px;
            width: 340px;
            text-align: left;
            word-break: break-all;
            padding-bottom: 10px;
          }
        }
      }
      .styleFooter {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 304px;
        background: #fcfcfc;
        border-radius: 16px;
        opacity: 1;
        border: 2px solid #6b6b6b;
        margin-top: 44px;
        box-sizing: border-box;
        .footerImg {
          width: 600px;
          height: 304px;
        }
      }
    }
    .desLine {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 690px;
      height: 40px;
      line-height: 40px;
      margin-top: 16px;
      margin-bottom: 18px;
      .line {
        width: 4px;
        height: 40px;
        margin-right: 16px;
        background-color: #d61a20;
      }
      .lineText {
        font-size: 32px;
        font-weight: 400;
        color: #d61a20;
      }
      .lineOther {
        font-size: 32px;
        font-weight: 400;
      }
    }
    .checkList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .topCheck {
        display: flex;
        flex-direction: row;
        padding: 22px 68px 74px 44px;
        width: 686px;
        height: 146px;
        background: #d6191f;
        border-radius: 14px 14px 0px 0px;
        opacity: 1;
        box-sizing: border-box;
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
        .middlePrice {
          margin: 0 58px 0 206px;
        }
      }
      .checkCotent {
        display: flex;
        flex-direction: column;
        width: 686px;
        // height: 2620px;
        background: #fcfcfc;
        box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.102);
        border-radius: 16px;
        margin-top: -70px;
        padding: 70px 40px 62px 28px;
        opacity: 1;
        box-sizing: border-box;
        .styleCheck {
          display: flex;
          flex-direction: row;
          font-size: 28px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-bottom: 42px;
          .require {
            width: 6px;
            height: 7px;
            margin-right: 20px;
            .requireImg {
              width: 100%;
              height: 100%;
            }
          }
          .right {
            width: 595px;
            min-height: 76px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            border-bottom: 1px solid #d9d9d9;
            .title {
              width: 300px;
              text-align: left;
              padding-bottom: 20px;
            }
            .price {
              width: 118px;
              text-align: right;
              margin-right: 125px;
            }
          }
        }
        .total {
          font-size: 28px;
          font-weight: 400;
          color: #333333;
          text-align: right;
        }
        .totalPrice {
          margin-top: 6px;
          font-size: 36px;
          font-weight: 400;
          color: #d6191f;
          text-align: right;
        }
      }
    }
    .footerList {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .topCheck {
        width: 686px;
        height: 146px;
        background: #d6191f;
        border-radius: 14px 14px 0px 0px;
        opacity: 1;
      }
      .footerContent {
        width: 686px;
        height: 814px;
        background: #fcfcfc;
        box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.102);
        border-radius: 16px 16px 16px 16px;
        opacity: 1;
        margin-top: -126px;
        padding: 102px 32px 0 50px;
        box-sizing: border-box;
        text-align: left;
        .footerTitle {
          font-size: 28px;
          font-weight: 400;
          color: #d6191f;
        }
        .projectName {
          font-size: 28px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          width: 605px;
          height: 80px;
          line-height: 80px;
          border-bottom: 1px solid #d9d9d9;
          margin-bottom: 18px;
        }
        .partitionTitle {
          display: flex;
          flex-direction: column;
          .partition {
            font-size: 28px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            position: relative;
            width: 605px;
            height: 100px;
            line-height: 100px;
            border-bottom: 1px solid #d9d9d9;
            padding-left: 44px;
            box-sizing: border-box;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              margin-top: -6px;
              margin-left: -44px;
              width: 12px;
              height: 12px;
              border-radius: 6px;
              background: #999999;
            }
          }
        }
      }
    }
  }
  .footerBtn {
    margin-top: 58px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .sureClick {
      width: 400px;
      height: 80px;
      background: #d6191f;
      border-radius: 40px;
      opacity: 1;
      border: 1px solid #d6191f;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: 80px;
      color: #ffffff;
      margin-right: 20px;
    }
    .advise {
      width: 182px;
      height: 80px;
      border-radius: 40px;
      opacity: 1;
      border: 1px solid #d61c22;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: 80px;
      color: #d61c22;
    }
  }
  .red {
    color: red;
  }
}
@media screen and (orientation: landscape) {
  .page {
    min-width: 750px !important;
    max-width: 850px !important;
    margin: auto !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
  }
}
</style>
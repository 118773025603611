import { render, staticRenderFns } from "./sdk.vue?vue&type=template&id=1146e806&scoped=true&"
var script = {}
import style0 from "./sdk.vue?vue&type=style&index=0&id=1146e806&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1146e806",
  null
  
)

export default component.exports
<template>
    <div class="page">
      <div class="headBgc"></div>
      <div class="navHead">
        <div class="nav">
          <div class="navName">意向订单表</div>
          <img
            :src="$ossRes('/common/arrow.png')"
            alt=""
            class="shareIcon"
            @click="back"
          />
        </div>
      </div>
      <div class="content">
        <div class="inner-content">
            <p class="title">项目名称</p>
            <div class="content-wrap" style="margin-bottom: 0;">
                <input type="text" v-model="order_name" class="inp" placeholder="请输入项目名">
            </div>
            <div class="arae-wrap">
              <span class="title">分区</span>
              <div class="area">
                <span :style="(index === areaIdx ? 'backgroundColor: #d5191f' : '')" v-for="(item, index) in areaList" :key="index" @click="setArea(item, index)">{{item}}</span>
              </div>
            </div>
            <ul>
                <li v-for="item in list" :key="item.title">
                    <!-- <i></i> -->
                    <input type="text" v-model="item.title" class="inp">
                </li>
            </ul>
            <div class="price-box">
                <p>订单合计(含税)</p>
                <span>¥{{price}}</span>
            </div>
        </div>
      </div>
        <div class="footer">
          <div class="lastStep" @click="back">上一步</div>
          <div class="nextStep" @click="submit">生成意向订单</div>
        </div>
    </div>
  </template>
<script>
  export default {
    data() {
      return {
        list: [],
        order_name: '',
        price: 0,
        function_inside: null,
        function_outside: null,
        order_temp: null,
        cango: true,
        areaList: [],
        areaIdx: 0,
        areaDate: [],
      };
    },
    created() {
        this.getList();
        this.getAreaList()
        let function_inside = JSON.parse(sessionStorage.getItem('function_inside'))
        let function_outside = JSON.parse(sessionStorage.getItem('function_outside'))
        let order_temp = JSON.parse(sessionStorage.getItem('order_temp'))
        let price = 0;
        // if(order_temp.template.type !== 3)
        //     function_inside.map(item => {
        //         price += item.price
        //     })
        function_outside.map(item => {
          if(item.checked)
            price += item.price
        })
        price +=  order_temp.template.type == 3 ? order_temp.template.per_price*Number(order_temp.template.area) : order_temp.template.base_price
        this.price = price
        this.function_inside = function_inside
        this.function_outside = function_outside
        this.order_temp = order_temp
    },
    methods: {
        getAreaList() {
            this.$http.get(
            "/sale/price/getPartitionTemplate",
            {
                type: this.$route.query.type,
            }
            ).then((res) => {
            if (res.code === 200 && res.data) {
              this.areaIdx = Number(sessionStorage.getItem('partitionIndex')) || 0
              let arr = []
              res.data.map(item => {
                arr.push(item.title)
              })
              this.areaList = arr;
              this.areaDate = res.data
            }
            });
        }, 
        setArea(item, idx){
          this.areaIdx = idx
          let arr = []
          this.areaDate[idx].data.map(item => arr.push({'title': item}))
          this.list = arr;
        },
        submit(){
          if(!this.order_name){
            this.$toast.fail('请输入项目名称')
            return
          }
          this.order_temp.template.title =  this.areaDate[this.areaIdx].title
          sessionStorage.setItem('partition',JSON.stringify(this.list))
          sessionStorage.setItem('partitionIndex',this.areaIdx)
          sessionStorage.setItem('order_name',this.order_name)
          sessionStorage.setItem('order_temp',JSON.stringify(this.order_temp))

          this.$router.push({
              name: "orderIntention",
              query: {
                userId: this.$route.query.userId,
              },
          })
        },
        getList() {
            this.$http.get(
            "/sale/price/getPartitionTemplate",
            {
                type: this.$route.query.type,
                num: this.$route.query.num,
            }).then((res) => {
            if (res.code === 200 && res.data) {
              if(sessionStorage.getItem('partition')){
                this.list = JSON.parse(sessionStorage.getItem('partition'))
              }else{
                let arr = [];
                res.data.data.map(item => {
                  arr.push({'title': item})
                })
                this.list = arr;
              }
            }
            });
        },
      back() {
        this.$router.back();
      },
    },
  };
  </script>
    
<style lang="less" scoped>
  .page {
    position: relative;
    width: 100%;
    height: 100%;
    // padding-bottom: 120px;
    background-color: #f2f2f2;
    // overflow-x: hidden;
    .headBgc {
      position: absolute;
      top: 0;
      width: 100%;
      height: 478px;
      // background-image: url("~@/assets/image/caseBgc.png");
      background-image:url("@{PublicEnv}/common/caseBgc.png");
      background-size: 100% 100%;
      z-index: 0;
    }
    .navHead {
      position: relative;
      width: 100%;
      height: 96px;
      z-index: 10;
      .nav {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 48px;
        box-sizing: border-box;
        height: 96px;
        line-height: 96px;
        text-align: center;
        .navName {
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .shareIcon {
          position: absolute;
          left: 48px;
          bottom: 23px;
          width: 50px;
          height: 54px;
        }
      }
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 15;
        position: relative;
        background: #E5373B;
        box-shadow: 0px 6px 30px 0px rgba(0,0,0,0.102);
        width: 686px;
        border-radius: 16px;
        margin: auto;
        .inner-content{
            margin-top: 30px;
            background: #FCFCFC;
            width: 686px;
            border-radius: 16px;
            .arae-wrap{
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 50px;
              .area{
                width: 518px;
                display: flex;
                justify-content: space-between;
                // flex-wrap: nowrap;
                // overflow: hidden;
                // overflow-x: scroll;
                span{
                  width: 124px;
                  height: 44px;
                  line-height: 44px;
                  background: #999;
                  color: #fff;
                  font-size: 24px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  display: block;
                  border-radius: 16px;
                  // margin-right: 84px;
                }
              }
            }
            .title{
                font-size: 28px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #D6191F;
                line-height: 40px;
                text-align: left;
                padding-left: 50px;
                padding: 32px 0 32px 46px;
            }
            .content-wrap{
                width: 605px;
                margin: auto auto 42px;
                border-bottom: 1px solid #D9D9D9;
                display: flex;
                align-items: center;
                padding-bottom: 28px;
                i{
                    width: 12px;
                    height: 12px;
                    background: #999999;
                    display: block;
                    border-radius: 100%;
                }
            }
            ul{
              li{
                width: 518px;
                height: 116px;
                margin: auto 50px 20px auto;
                // margin: auto auto 42px;
                // border-bottom: 1px solid #D9D9D9;
                display: flex;
                align-items: center;
                border-radius: 12px 12px 12px 12px;
                border: 1px solid #CCCCCC;
                // padding-bottom: 28px;
                i{
                    width: 12px;
                    height: 12px;
                    background: #999999;
                    display: block;
                    border-radius: 100%;
                }
              }
            }
            .inp{
                width: 518px;
                height: 34px;
                font-size: 28px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                line-height: 34px;
                padding-left: 10px;
                // margin-bottom: 28px;
                border: none;
                background: #FCFCFC;
            }
            .price-box{
                text-align: right;
                padding-right: 36px;
                padding-bottom: 50px;
                p{
                    font-size: 30px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 40px;
                }
                span{
                    font-size: 36px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #D6191F;
                    // line-height: 50px;
                    padding-top: 26px;
                }
            }
        }
    }
      .footer {
        width: 100%;
        height: 96px;
        line-height: 96px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
        .lastStep {
          width: 320px;
          height: 96px;
          background: #cccccc;
          border-radius: 48px 48px 48px 48px;
          opacity: 1;
          margin-right: 14px;
        }
        .nextStep {
          width: 320px;
          height: 96px;
          background: #d61a20;
          border-radius: 48px 48px 48px 48px;
          opacity: 1;
        }
      }
  }
  @media screen and (orientation: landscape) {
    .page {
      min-width: 750px !important;
      max-width: 850px !important;
      margin: auto !important;
      overflow: hidden !important;
      border-radius: 0 !important;
      border: 0 !important;
      padding: 0 !important;
    }
  }
  </style>
import Vue from "vue";
import {
  Area,
  Popup,
  Toast,
  Uploader,
  Popover,
  Grid,
  GridItem,
  Icon,
  ImagePreview,
  Dialog,
  Lazyload,
  ShareSheet,
  Picker,
  Loading,
  Swipe,
  SwipeItem,
  Field,
  Form,
  DatetimePicker,
  RadioGroup,
  Radio,
  Button,
  Checkbox, 
  CheckboxGroup
} from "vant";
import "vant/lib/index.css";

Vue.use(Area)
  .use(Popup)
  .use(Uploader)
  .use(Popover)
  .use(Grid)
  .use(GridItem)
  .use(Icon)
  .use(Dialog)
  .use(ImagePreview)
  .use(Toast)
  .use(Lazyload)
  .use(ShareSheet)
  .use(Picker)
  .use(Loading)
  .use(Swipe)
  .use(SwipeItem)
  .use(Field)
  .use(Form)
  .use(DatetimePicker)
  .use(RadioGroup)
  .use(Radio)
  .use(Button)
  .use(Checkbox)
  .use(CheckboxGroup);

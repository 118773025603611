/* eslint-disable no-undef */
import { getUrlParam, getUrlParamsAll, isWechat } from "./utils";
import { get } from "./http";
const AUTH_URL =
  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_userinfo&state=STATE&forcePopup=true&forceSnapShot=true#wechat_redirect";
export const getAuthUrl = (redirectUrl) => {
  return AUTH_URL.replace("APPID", process.env.VUE_APP_WECHAT_APPID).replace(
    "REDIRECT_URI",
    encodeURIComponent(redirectUrl)
  );
};
/**
 * 获取微信授权
 */
export const doAuth = () => {
  return new Promise((resolve) => {
    // localStorage.clear();
    const shareCode = getUrlParam("share_code") || localStorage.getItem("share_code");
    shareCode && localStorage.setItem("share_code", shareCode);
    const id = getUrlParam("id") || localStorage.getItem("fromId");
    id && localStorage.setItem("fromId", id);
    const sId = getUrlParam("sid") || localStorage.getItem("sId");
    sId && localStorage.setItem("sId", sId);
    if (isWechat()) {
      const code = getUrlParam("code");
      const debug = getUrlParam("debug");
      console.log(code, "code");
      if (!code) {
        resolve(false);
        location.href = getAuthUrl(location.href);
      } else {
        if (!debug) {
          const requestParam = {
            code: code,
          };
          if (shareCode) {
            requestParam["share_code"] = shareCode;
          }
          if (id) {
            requestParam["user_id"] = id;
          }
          if (sId) {
            requestParam["share_user_id"] = sId;
          }
          get("/sale/h5/wxAuth", requestParam).then((res) => {
            if (res.code === 200 && res.data) {
              Object.keys(res.data).forEach((i) => {
                localStorage.setItem(i, res.data[i]);
              });
              resolve(true);
            } else {
              resolve(false);
            }
          });
        }
      }
    } else {
      if (id) {
        setUserInfo(id).then(() => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    }
  });
};
/**
 * 微信配置初始化
 */
export const initConfig = (url) => {
  return new Promise((resolve) => {
    get("/sale/h5/share", {
      localUrl: url,
    }).then((res) => {
      if (res.code === 200) {
        setConfig(res.data).then((pass) => {
          if (pass) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  });
};
/**
 * 微信配置注入
 */
export const setConfig = (config) => {
  return new Promise((resolve) => {
    const { appId, timestamp, nonceStr, signature } = config;
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: [
        "updateAppMessageShareData",
        "updateTimelineShareData",
        "wx-open-launch-weapp",
        "wx-open-subscribe",
        "chooseImage",
      ], // 必填，需要使用的JS接口列表,
      openTagList: ["wx-open-launch-weapp", "wx-open-subscribe"],
      fail(err) {
        console.log("setShareConfig err", err);
      },
    });
    resolve(true);
  });
};
/**
 * 微信分享注入
 */
export const wxShare = (option) => {
  !option && (option = {});
  const url = option.url || location.href;
  return initConfig(url).then((pass) => {
    if (pass) {
      const { title, link, desc, imgUrl } = option;
      console.log(option, "wxShare");
      let shareLink = link
        ? process.env.VUE_APP_SUB_URL + link
        : process.env.VUE_APP_SUB_URL;
      shareLink += `${
        shareLink.indexOf("?") >= 0 ? "&" : "?"
      }id=${localStorage.getItem("fromId")}`;
      shareLink += `${
        shareLink.indexOf("?") >= 0 ? "&" : "?"
      }sid=${localStorage.getItem("id")}`;
      shareLink += `${
        shareLink.indexOf("?") >= 0 ? "&" : "?"
      }share_code=${localStorage.getItem("share_code")}`;
      shareLink = shareLink.replace(/[?&]code=[^&]*&?/g, "&");
      const shareParam = {
        title: title || process.env.VUE_APP_SHARE_DEFAULT_TITLE, // 分享标题
        desc: desc || process.env.VUE_APP_SHARE_DEFAULT_DESC, // 分享描述
        link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl || process.env.VUE_APP_SHARE_IMG, // 分享图标
      };
      wx.ready(() => {
        // 需在用户可能点击分享按钮前就先调用
        wx.updateTimelineShareData({
          ...shareParam,
          success() {
            console.log("updateTimelineShareData success");
          },
          fail(err) {
            console.log(err, "updateTimelineShareData");
          },
        });
        wx.updateAppMessageShareData({
          ...shareParam,
          success() {
            console.log("updateAppMessageShareData success");
          },
          fail(err) {
            console.log(err, "updateAppMessageShareData");
          },
        });
      });
    }
    return pass;
  });
};
export const setUserInfo = (id) => {
  return get(
    "/sale/h5/userinfo",
    {
      user_id: id,
    },
    true
  ).then((res) => {
    if (res.code === 200 && res.data) {
      Object.keys(res.data).forEach((i) => {
        localStorage.setItem(i, res.data[i]);
      });
    }
    return true;
  });
};
export const checkUserConfirm = ()=>{
  return localStorage.getItem('phone_validate') > 0;
}
export const getUserInfo = () => {
  const userInfoNames = [
    "avatar",
    "company",
    "content",
    "id",
    "nickname",
    "openid",
    "phone",
    "position",
    "product_id",
    "product_link",
    "sale_user_id",
    "share_url",
    "status",
    "type",
    "unionid",
    "user_name",
  ];
  const userInfo = {};
  userInfoNames.map((item) => {
    userInfo[item] = localStorage.getItem(item) || "";
  });
  return userInfo;
};
/**
 * 分享配置
 */
export const shareData = {
  home: {
    title: "",
    desc: "",
    imgUrl: "",
    link: "",
  },
};

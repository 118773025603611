<template>
  <div>
    <van-popup
      v-model="show"
      :close-on-click-overlay="false"
      :lock-scroll="false"
      style="background-color: transparent; overflow: hidden"
    >
      <div class="page" @click="close">
        <img :src="$ossRes('/common/orderTips.gif')" alt="" />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  img {
    width: 750px;
    height: 100%;
  }
}
</style>

<template>
    <div class="page">
      <div class="navHead">
        <div class="nav">
          <div class="navName">意向订单表</div>
          <img
            :src="$ossRes('/common/arrow.png')"
            alt=""
            class="shareIcon"
            @click="back"
          />
        </div>
      </div>
      <div class="content">
        <div class="desLine">
          <div class="line"></div>
          <p class="lineText">套餐外附加可选</p>
        </div>
        <div class="comboList">
          <div v-for="(item, index) in list" :key="index" class="combo-item">
                <div class="item-card">
                    <div class="item-card-child item-card-child-top">
                        <span class="item-card-l">{{item.title}}</span>
                        <!-- <span class="item-card-r item-card-detail" @click="jumpto(item.link)">详情></span> -->
                    </div>
                    <div class="item-card-child item-card-child-remark">
                        <span class="item-card-l">{{item.remark}}</span>
                    </div>
                    <div class="item-card-child">
                        <span class="item-card-l item-card-price">¥{{item.price}}</span>
                        <div class="item-card-r tem-model-chk-box" @click="choseCom(item, index)">
                            <span :class="[item.checked ? 'tem-model-chk-box-checked' : 'tem-model-chk-box-none']"></span>
                            <span>选择</span>
                        </div>
                    </div>
                </div>
          </div>
        </div>
        <div class="footer">
          <div class="lastStep" @click="back">上一步</div>
          <div class="nextStep" @click="next">下一步</div>
        </div>
      </div>
    </div>
  </template>
<script>
  import { get, post } from "@/assets/js/http.js";
  export default {
    data() {
      return {
        list: [],
      };
    },
    created() {
      this.getList();
    },
    watch: {},
    methods: {
        jumpto(href){
            // window.location.href = href
            window.open(href);
        },
        choseCom(item, index){
            this.list[index].checked = item.checked ? 0 : 1
        },
        back() {
            this.$router.back();
        },
        next(){
            // let arr = []
            // if(this.list.length > 0){
            //     this.list.map(item => {
            //         if(item.checked)
            //         arr.push(item)
            //     })
            // }
            // if(arr.length < 1){
            //     this.$toast.fail("请选择套餐");
            //     return
            // }
            sessionStorage.setItem('function_outside', JSON.stringify(this.list))
            this.$router.push({
                name: "setOrder",
                query: {
                    userId: this.$route.query.userId,
                    type: this.$route.query.type,
                    num: this.$route.query.num,
                },
            })
        },
        getList() {
          let function_outside = sessionStorage.getItem('function_outside')
          if(function_outside){
            this.list = JSON.parse(sessionStorage.getItem('function_outside'))
          } else
            this.$http.get(
              "/sale/price/getPackageTemplate",
              {
                  type: this.$route.query.type,
                  num: this.$route.query.num,
              }
              ).then((res) => {
              if (res.code === 200 && res.data) {
                  if(res.data.function_inside.length > 0){
                  res.data.function_outside.map(item => {
                      item.checked = 0
                  })
                  }
                  this.list = res.data.function_outside;
              }
            });
        },
    },
  };
  </script>
      
<style lang="less" scoped>
  .page {
    position: relative;
    width: 100%;
    height: 100%;
    // padding-bottom: 120px;
    background-color: #f2f2f2;
    // overflow-x: hidden;
    .navHead {
      position: relative;
      // background-image: url("~@/assets/image/navHead.png");
      background-image:url("@{PublicEnv}/common/navHead.png");
      background-size: 100% 100%;
      width: 100%;
      height: 96px;
      z-index: 10;
      .nav {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 48px;
        box-sizing: border-box;
        height: 96px;
        line-height: 96px;
        text-align: center;
        .navName {
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .shareIcon {
          position: absolute;
          left: 48px;
          bottom: 23px;
          width: 50px;
          height: 54px;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 15;
      .desLine {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 690px;
        height: 40px;
        line-height: 40px;
        margin-top: 22px;
        margin-bottom: 12px;
        .line {
          width: 4px;
          height: 40px;
          margin-right: 16px;
          background-color: #d61a20;
        }
        .lineText {
          font-size: 28px;
          font-weight: 400;
          color: #d61a20;
        }
      }
      .comboList {
        width: 700px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .combo-item {
          position: relative;
          width: 692px;
          // height: 214px;
          margin-bottom: 34px;
          background: #DA181E;
          border-radius: 16px 16px 0 0;
            .item-card{
                margin-top: 10px;
                background: #fff;
                height: 100%;
                border-radius: 16px 16px 0 0;
                .item-card-l{
                    width: 470px;
                    display: block;
                    text-align: left;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                }
                .item-card-r{
                    span{
                        display: block;
                    }
                }
                .item-card-child{
                    font-size: 32px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 26px;
                    .item-card-detail{
                        width: 114px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 6px;
                        border: 1px solid #DA181E;
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #DA181E;
                    }
                    .item-card-price{
                        font-size: 28px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #D6191F;
                        line-height: 50px;
                        padding-bottom: 10px;
                    }
                }
                .item-card-child-top{
                    padding: 18px 26px 8px;
                }
                .item-card-child-remark{
                    font-size: 28px;
                    min-height: 72px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .tem-model-chk-box{
                    display: flex;
                    align-items: center;
                    position: relative;
                    color: #999;
                    font-size: 24px;
                    .tem-model-chk-box-checked{
                        width: 32px;
                        height: 32px;
                        border-radius: 100%;
                        background: #fff;
                        position: relative;
                        border: 2px solid #F4303F;
                        color: #333;
                        margin-right: 4px;
                        &::after{
                            content: ' ';
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            left: 0;
                            right: 0;
                            top: 6px;
                            margin: auto;
                            background: #F4303F;
                            border-radius: 100%;
                        }
                    }
                    .tem-model-chk-box-none{
                        width: 32px;
                        height: 32px;
                        border-radius: 100%;
                        border: 2px solid #999;
                        margin-right: 4px;
                    }
            }
          }
        }
      }
      .footer {
        width: 100%;
        height: 96px;
        line-height: 96px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
        .lastStep {
          width: 320px;
          height: 96px;
          background: #cccccc;
          border-radius: 48px 48px 48px 48px;
          opacity: 1;
          margin-right: 14px;
        }
        .nextStep {
          width: 320px;
          height: 96px;
          background: #d61a20;
          border-radius: 48px 48px 48px 48px;
          opacity: 1;
        }
      }
    }
  }
  @media screen and (orientation: landscape) {
    .page {
      min-width: 750px !important;
      max-width: 850px !important;
      margin: auto !important;
      overflow: hidden !important;
      border-radius: 0 !important;
      border: 0 !important;
      padding: 0 !important;
    }
  }
  </style>
<template>
  <div>
    <div class="page">
      <van-popup
        v-model="show"
        :close-on-click-overlay="false"
        :lock-scroll="false"
        style="border-radius: 16px"
      >
        <div class="success">
          <!-- <div class="successHead"></div> -->
          <div class="successTitle">提交成功</div>
          <div class="successText">{{tips}}</div>
          <div class="successBtn">
            <div class="sureClick" @click="close">确定</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      comments: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less">
.van-popup {
  background-color: transparent;
  overflow: hidden;
}
</style>
<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  .success {
    width: 686px;
    height: 568px;
    box-sizing: border-box;
    // background: url('../assets/image/success-head.png');
    background: url("@{PublicEnv}/common/success-head.png");
    background-size: 686px 568px;
    .successTitle {
      width: 100%;
      height: 56px;
      font-size: 40px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #d6161c;
      line-height: 56px;
      text-align: center;
      padding-top:214px;
      margin-bottom: 20px;
    }
    .successText {
      width: 100%;
      height: 44px;
      font-size: 32px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
      text-align: center;
    }
    .successBtn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 62px;
      .sureClick {
        width: 554px;
        height: 96px;
        line-height: 96px;
        background: #d6161c;
        box-shadow: 0px 0px 40px 0px rgba(83, 83, 83, 0.302);
        border-radius: 48px 48px 48px 48px;
        opacity: 1;
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>

<template>
  <div class="page">
    <div class="headBgc"></div>
    <div class="navHead">
      <div class="nav">
        <div class="navName">意向订单表</div>
        <img
          :src="$ossRes('/common/arrow.png')"
          alt=""
          class="shareIcon"
          @click="back"
        />
      </div>
    </div>
    <div class="content">
      <div class="infomation">
        <div class="styleInfo">
          <img :src="$ossRes('/common/name.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>姓名</p>
            <!-- <input type="text" v-model="userInfo.user_name"> -->
            <span>{{ userInfo.user_name }}</span>
          </div>
        </div>
        <div class="styleInfo">
          <img :src="$ossRes('/common/tel.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>手机号</p>
            <!-- <input v-model="userInfo.phone" maxlength="11" type="tel"> -->
            <span>{{ userInfo.phone }}</span>
          </div>
        </div>
        <div class="styleInfo">
          <img :src="$ossRes('/common/company.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>公司/单位</p>
            <!-- <input type="text" v-model="userInfo.company"> -->
            <span>{{ userInfo.company }}</span>
          </div>
        </div>
        <div class="styleInfo">
          <img :src="$ossRes('/common/job.png')" alt="" class="infoImg" />
          <div class="infoInput">
            <p>职务</p>
            <!-- <input type="text" v-model="userInfo.position"> -->
            <span>{{ userInfo.position }}</span>
          </div>
        </div>
      </div>
      <img class="content-top-img" :src="$ossRes('/common/overlay.png')" alt="" srcset="">
    </div>
    <div class="chooseModel">
      <van-radio-group v-model="result">
        <div class="extra_o">
          <div class="extra_o-item">
            <van-radio
              :class="[currentIndex == idx ?'extra_o-radio-active':'extra_o-radio']"
              v-for="(it, idx) in selList"
              :key="idx"
              icon-size="16px"
              checked-color="#F4303F"
              :name="it.name"
              @click="selTempRadio(it, idx)"
              direction="horizontal"
            >
              <span :style="{color:currentIndex == idx ? '#fff' : ''}">{{ it.name }}</span>
            </van-radio>
          </div>
        </div>
      </van-radio-group>
      <div v-show="currentIndex !== 2">
        <swiper class="swiper-container" :options="swiperOptions" ref="mySwiper" @slide-change-transition-end="hdClickSlide" v-if="swiperList.length">
            <swiper-slide class="swiper-slide" v-for="item in swiperList[currentIndex].template" :key="item.id">
              <div class="item">
                <img class="swiper-slide-img" :src="item.pic" alt="" srcset="">
                <p>{{item.text}}</p>
                <!-- <span>{{item.text}}</span> -->
              </div>
            </swiper-slide>
        </swiper>
      </div>
      <div class="template" v-if="swiperList.length">
        <div class="tem-model">
          <div class="tem-model-chk-box" @click="checkFunc(currentIndex, realIndex)">
            <span :class="[swiperList[currentIndex].template[realIndex].checked ? 'tem-model-chk-box-checked' : 'tem-model-chk-box-none']"></span>
            <span class="tem-model-chk-box-text">{{swiperList[currentIndex].template[realIndex].text}}</span>
          </div>
          <div class="tem_space">
            <div>空间面积：</div>
            <div :class="['area', currentIndex !== 2 ? 'area-bg' : '']">
              <input type="number" v-model="swiperList[currentIndex].area" v-if="currentIndex === 2">
              <span v-else>{{swiperList[currentIndex].area}}</span>
              m²
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nextSteps">
      <div class="nextBtn" @click="goNext">下一步</div>
    </div>
  </div>
</template>
  <script>
import { get, post } from "@/assets/js/http.js";
export default {
  data() {
    return {
      result: "标准版",
      tem_result: [],
      currentIndex: 0,
      area: "",
      selList: [
        { name: "标准版", type: 1 },
        { name: "高配版", type: 2 },
        { name: "定制版", type: 3 },
      ],
      swiperList: [],
      chooseType: 1,
      realIndex:0,
      swiperOptions: {
        // width: 600,
        // height: 300,
        // slidesPerView: 'auto',
        centeredSlides: true,
        slidesPerView: 1.25,
        spaceBetween: 10,
        loop: false
      },
      userInfo: {},
      template: {}
    };
  },
  created() {
    sessionStorage.removeItem('function_inside')
    sessionStorage.removeItem('function_outside')
    this.$http.get("/sale/price/getPriceTemplate", {type: 0}).then(res => {
      if(res.code == 200){
        // 用户数据回显
        let order_temp = null
        if(sessionStorage.getItem('order_temp')){
          order_temp = JSON.parse(sessionStorage.getItem('order_temp'))
          this.userInfo = {
            id: localStorage.getItem('id'),
            user_name: order_temp.user_name,
            phone: order_temp.phone,
            company: order_temp.company,
            position: order_temp.position,
          }
          this.template = order_temp.template
        }else
          this.userInfo = {
            id: localStorage.getItem('id'),
            user_name: localStorage.getItem('user_name'),
            phone: localStorage.getItem('phone'),
            company: localStorage.getItem('company'),
            position: localStorage.getItem('position'),
          }
        let arr = [res.data[1],res.data[2],res.data[3]]
        arr.map((item, index) => {
          if (item.template.length > 0){
            item.template.map((it, idx) => {
              if(order_temp){
                if(index+1 === order_temp.template.type && idx+1 === order_temp.template.no)
                  it.checked = true
                else it.checked = false
              }
              else it.checked = false
            })
          }
          item.type = index +1
        })
        this.swiperList = arr
        // if(this.swiperList[0].template.length > 1){
        //   this.swiperOptions.loop = true
        // }else{
        //   this.swiperOptions.loop = false
        // }
        this.checkFunc(0, 0)
      }
    })
  },
  mounted(){
  },
  methods: {
    checkFunc(cidx, ridx){
      let that = this
      this.swiperList.map((item, index) => {
        if(cidx === index){
          item.template.map((it, idx) => {
            if(idx === ridx){
              it.checked = !it.checked
              if(it.checked){
                that.template = {
                  type: item.type ? item.type : null,
                  no: idx+1,
                  img: it.pic ? it.pic : null,
                  area: item.area ? item.area : null,
                  base_price: it.base_price ? it.base_price : null,
                  per_price: it.per_price ? it.per_price : null,
                  name: it.name,
                  text: it.text
                }
              }else{
                that.template = {}
              }
            }
            else 
              it.checked = false
          })
        }else {
          item.template.map((it, index) => {
              it.checked = false
          })
        }
      })
    },
    hdClickSlide() {
      let index = this.$refs.mySwiper.$swiper.realIndex
      this.realIndex = index
      this.checkFunc(this.currentIndex, index)
    },
    back() {
      this.$router.back();
    },
    selTempRadio(it, idx) {
      this.currentIndex = idx;
      this.chooseType = it.type;
      this.realIndex = 0
      // if(this.swiperList[idx].template.length > 1){
      //   this.swiperOptions.loop = true
      // }else{
      //   this.swiperOptions.loop = false
      // }
      // console.log(this.swiperOptions)
      this.checkFunc(idx, 0)
    },
    goNext() {
      this.template.area = Number(this.swiperList[this.currentIndex].area)
      let data = {
        id: this.userInfo.id,
        user_name: this.userInfo.user_name,
        phone: this.userInfo.phone,
        company: this.userInfo.company,
        position: this.userInfo.position,
        // base_price: this.userInfo.base_price,
        template: this.template
      }
      if(!data.template.type){this.$toast.fail("请选择模板");return;}
      sessionStorage.setItem('order_temp',JSON.stringify(data))
      this.$router.push({
        name: "onCombo",
        query: {
          userId: this.$route.query.userId,
          type: data.template.type,
          num: data.template.no
        },
      })
    }
  },
};
</script>
  
<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  // padding-bottom: 120px;
  background-color: #f2f2f2;
  // overflow-x: hidden;
  .headBgc {
    position: absolute;
    top: 0;
    width: 100%;
    height: 478px;
    background-image: url("@{PublicEnv}/common/caseBgc.png");
    background-size: 100% 100%;
    z-index: 0;
  }
  .navHead {
    position: relative;
    width: 100%;
    height: 96px;
    z-index: 10;
    .nav {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 48px;
      box-sizing: border-box;
      height: 96px;
      line-height: 96px;
      text-align: center;
      .navName {
        font-size: 36px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .shareIcon {
        position: absolute;
        left: 48px;
        bottom: 23px;
        width: 50px;
        height: 54px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 15;
    position: relative;
    .infomation {
      width: 686px;
      height: 500px;
      background: #fcfcfc;
      box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.102);
      border-radius: 16px;
      opacity: 1;
      margin-top: 34px;
      padding: 32px 43px;
      box-sizing: border-box;
      z-index: 1;
      .styleInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 32px;
        position: relative;
        .infoImg {
          width: 40px;
          height: 40px;
        }
        .infoInput {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 547px;
          height: 72px;
          line-height: 45px;
          font-size: 28px;
          font-weight: 400;
          color: #333333;
          position: relative;
          // border-bottom: 1px solid #cccccc;
          input, span {
            width: 342px;
            height: 40px;
            border: none;
            background: transparent;
            text-align: right;
          }
          &::after{
            content: ' ';
            background: #ccc;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transform: scaleY(.5);
          }
        }
      }
    }
    .content-top-img{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 654px;
      margin: auto;
    }
  }
  .chooseModel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 46px;
    .extra_o {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      // align-items: center;
      width: 600px;
      height: 94px;
      background: #ffffff;
      border-radius: 14px 14px 0px 0px;
      // padding: 0 40px;
      // opacity: 1;
      // box-sizing: border-box;
      .extra_o-item{
        display: flex;
        align-items: center;
        .extra_o-radio{
          width: 200px;
          height: 94px;
          display: inline-flex;
          justify-content: center;
        }
        .extra_o-radio-active{
          width: 200px;
          height: 94px;
          display: inline-flex;
          justify-content: center;
          background: linear-gradient(180deg, #FF4554 0%, #BC0311 100%);
          border-radius: 14px 14px 0px 0px; 
            // color: #FFFFFF;
        }
      }
    }
    .chooseItem {
      width: 200px;
      height: 146px;
      background: linear-gradient(180deg, #ff4554 0%, #bc0311 100%);
      border-radius: 14px 14px 0px 0px;
      opacity: 1;
    }
    // .swipe {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   .my-swipe {
    //     width: 600px;
    //     height: 300px;
    //     background-color: red;
    //     .coverImg {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
      .swiper-container{
        width: 750px;
        height: 100%;
      }
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        // width: 600px;
        height: 300px;
        .item{
          width: 100%;
          height: 100%;
        }
        .swiper-slide-img{
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    .template {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      .tem-model {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 686px;
        height: 120px;
        background: #fcfcfc;
        box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.102);
        border-radius: 16px 16px 16px 16px;
        opacity: 1;
        padding: 30px 28px 30px 68px;
        box-sizing: border-box;
        .tem-model-chk-box{
          display: flex;
          align-items: center;
          position: relative;
          .tem-model-chk-box-checked{
            width: 32px;
            height: 32px;
            border-radius: 100%;
            background: #fff;
            position: relative;
            border: 2px solid #F4303F;
            &::after{
              content: ' ';
              position: absolute;
              width: 20px;
              height: 20px;
              left: 0;
              right: 0;
              top: 6px;
              margin: auto;
              background: #F4303F;
              border-radius: 100%;
            }
          }
          .tem-model-chk-box-none{
            width: 32px;
            height: 32px;
            border-radius: 100%;
            border: 2px solid #F4303F;
          }
          .tem-model-chk-box-text{
            font-size: 24px;
            color: #333;
            padding-left: 12px;
          }
        }
        .tem_space {
          display: flex;
          flex-direction: row;
          align-items: center;
          .area {
            width: 248px;
            height: 60px;
            line-height: 60px;
            background: #fff;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 2px solid #eeeeee;
            input{
              width: 180px;
              height: 60px;
              line-height: 60px;
              text-align: center;
              border: none;
              background: #fff;
            }
          }
          .area-bg{
            background: #e3e3e3;
          }
        }
      }
    }
  }
  .nextSteps {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    margin-top: 48px;
    .nextBtn {
      width: 490px;
      height: 80px;
      line-height: 80px;
      background: #d6191f;
      border-radius: 40px 40px 40px 40px;
      opacity: 1;
      border: 1px solid #d6191f;
      font-size: 32px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
@media screen and (orientation: landscape) {
  .page {
    min-width: 750px !important;
    max-width: 850px !important;
    margin: auto !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
  }
}
</style>
<template>
  <div class="page">
    <toper :title="'平川党建+智慧体验云平台'" :back="true"></toper>
    <div class="content">
      <div class="companyDes" @click="goOfficialWebsite">
        <img :src="poster" alt="" class="companyImg" />
      </div>
      <div class="desBanner">
        <img :src="banner" alt="" class="desSrc" @click="goExhibition(exhibitionId, productType)" />
        <div class="desFooter">
          <div class="leftDes">
            <p class="footerTitle">{{ name }}</p>
            <p class="englishDes">{{title_en}}</p>
          </div>
          <img class="rightDes" src="~@/assets/image/toRight.png" alt="" />
        </div>
      </div>
      <div class="desLine">
        <div class="line"></div>
        <p class="lineText">更多案例</p>
      </div>
      <div class="moreCases">
        <div
          v-for="(item, index) in moreList"
          :key="index"
          class="moreList"
          @click="goExhibition(item.id, item.product_type)"
        >
          <img :src="item.img" alt="" class="moreImg" />
          <div class="moreFooter" v-if="item.name">
            <p class="moreTitle">{{ item.name }}</p>
            <p class="moreEnglish">{{ item.title_en }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { wxShare } from "@/assets/js/wechatAuth.js";
import { get } from "@/assets/js/http.js";
import toper from "../components/toper.vue";
export default {
  components: {
    toper,
  },
  name: "",
  data() {
    return {
      name: "",
      poster: "",
      exhibitionId: "",
      productType: "",
      banner: "",
      banner_link: "",
      title: "",
      title_en: "",
      moreList: [],
      userInfo: {},
    };
  },
  created() {
    this.userInfo = this.$getUserInfo();
    this.getConfig();
    this.getMoreList();
  },
  methods: {
    goOfficialWebsite(){
      window.open('https://m.333f.com/')
    },
    goExhibition(id, productType) {
      this.$router.push({
        name: "exhibition",
        query: {
          exhibitionId: id,
          productType: productType
        },
      });
    },
    getMoreList() {
      get("/sale/h5/moreCase", {
            user_id: this.userInfo.id,
          }, true).then((res) => {
        if (res.code === 200 && res.data) {
          this.moreList = res.data;
        }
      });
    },
    getConfig() {
      get("/sale/h5/morePageConfig", {
            user_id: this.userInfo.id,
            share_code: localStorage.getItem('share_code'),
          }, true).then((res) => {
        if (res.code === 200 && res.data) {
          this.poster = res.data.xuan_chuan;
          this.exhibitionId = res.data.id;
          this.productType = res.data.product_type;
          this.banner = res.data.banner;
          this.banner_link = res.data.banner_link;
          this.title = res.data.title;
          this.title_en = res.data.title_en || '';
          this.name = res.data.name || '';
        }
      });
    },
    toBanner() {
      window.open(this.banner_link, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f2f2f2;
}
.page {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  overflow-x: hidden;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .companyDes {
      margin-top: 42px;
      width: 690px;
      height: 340px;
      border-radius: 12px;
      box-sizing: border-box;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
      opacity: 1;
      .companyImg {
        width: 100%;
        height: 100%;
      }
    }
    .desBanner {
      margin-top: 21px;
      position: relative;
      width: 690px;
      height: 486px;
      box-sizing: border-box;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
      .desSrc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .desFooter {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        bottom: 0;
        width: 100%;
        height: 104px;
        background: #999999;
        box-sizing: border-box;
        .leftDes {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .footerTitle {
            font-size: 32px;
            font-weight: 400;
            color: #ffffff;
            text-align: left;
          }
          .englishDes {
            font-size: 20px;
            font-weight: 200;
            color: #ffffff;
            opacity: 42%;
            text-align: left;
          }
        }
        .rightDes {
          width: 40px;
          height: 40px;
        }
      }
    }
    .desLine {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 690px;
      height: 40px;
      line-height: 40px;
      margin-top: 72px;
      .line {
        width: 4px;
        height: 40px;
        margin-right: 16px;
        background-color: #d61a20;
      }
      .lineText {
        font-size: 28px;
        font-weight: 400;
        color: #d61a20;
      }
    }
    .moreCases {
      margin-top: 20px;
      width: 690px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .moreList {
        position: relative;
        width: 100%;
        height: 405px;
        margin-bottom: 30px;
        box-sizing: border-box;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
        .moreImg {
          width: 100%;
          height: 100%;
        }
        .moreFooter {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          backdrop-filter: blur(10px);
          left: 0;
          bottom: 0;
          height: 118px;
          background: rgba(255, 134, 140, 0.2);
          border-radius: 0px 0px 12px 12px;
          opacity: 0.9;
          width: 100%;
          padding-left: 30px;
          box-sizing: border-box;
          .moreTitle {
            font-size: 32px;
            font-weight: 400;
            color: #ffffff;
          }
          .moreEnglish {
            font-size: 24px;
            font-weight: 300;
            color: #ffffff;
            line-height: 28px;
          }
        }
      }
    }
  }
}
@media screen and (orientation: landscape) {
  .page {
    min-width: 750px !important;
    max-width: 850px !important;
    margin: auto !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
  }
}
</style>

<template>
  <div class="page">
    <toper :title="'平川'" :share="true" @doShare="shareFriend"></toper>
    <div class="content">
      <div class="topContent">
        <div class="moreLink" @click="goMore">了解更多</div>
        <!-- <div class="downloadLink">
          <p class="downloadText">点击下载APP,体验更多</p>
          <div class="downloadBtn" @click="download">下载</div>
        </div> -->
      </div>
      <div class="desBanner" v-if="banner" @click="goExhibition">
        <img :src="banner" alt="" class="desSrc" />
        <div class="desFooter">
          <div class="leftDes">
            <p class="footerTitle">{{ name }}</p>
            <p class="englishDes">{{ title_en }}</p>
          </div>
          <img class="rightDes" :src="$ossRes('/common/toRight.png')" alt="" />
        </div>
      </div>
      <div
        class="middleBanner"
        v-if="propaganda?.index?.url"
        @click="goWechatArticle"
      >
        <img :src="propaganda?.index?.url" alt="" class="middleImg" />
      </div>
      <!-- <div class="footerBanner" v-if="video.cover">
        <img :src="video.cover" alt="" class="footerImg" />
      </div> -->
      <div class="footerBtn">
        <div class="offer" @click="ToIntention">
          <div class="inOffer">商务报价</div>
        </div>
        <div class="build">
          <div class="buildWord" @click="toBuild">
            <p>我要</p>
            <p>建馆</p>
          </div>
        </div>
        <div class="exclusive" @click.stop.prevent="callSale">
          <div class="inexclusive">专属商务</div>
        </div>
      </div>
      <div class="message" @click.stop.prevent="popShow">留言反馈</div>
    </div>
    <share-wx :show="showShareWx" @close="closeShareWx"></share-wx>
    <pop
      :show="showPop"
      @close="closePop"
      :title="'留言反馈'"
      @submit="feedbackSubmit"
    >
      <div class="pop-info">
        <div class="info-item">
          <div class="left">
            <img :src="$ossRes('/common/pop-name.png')" />
            <span>姓名</span>
          </div>
          <div class="right">
            <!-- <input v-model="userInfo.user_name" /> -->
            <span>{{ userInfo.user_name }}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="left">
            <img :src="$ossRes('/common/pop-tel.png')" />
            <span>手机号</span>
          </div>
          <div class="right">
            <!-- <input v-model="userInfo.phone" /> -->
            <span>{{ userInfo.phone }}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="left">
            <img :src="$ossRes('/common/pop-company.png')" />
            <span>公司/单位</span>
          </div>
          <div class="right">
            <!-- <input v-model="userInfo.company" /> -->
            <span>{{ userInfo.company }}</span>
          </div>
        </div>
        <div class="more-item">
          <textarea v-model="feedbackContent"></textarea>
        </div>
      </div>
    </pop>
    <pop-success
      :show="showPopSuccess"
      @close="closePopSuccess"
      :tips="'将有专属商务与您联系，请保持手机畅通'"
    ></pop-success>
    <pop-user-confirm
      :show="showUserConfirm"
      @success="popUserConfirmSuccess"
      @close="closeUserConfirm"
    ></pop-user-confirm>
  </div>
</template>
<script>
import { wxShare } from "@/assets/js/wechatAuth.js";
import shareWx from "@/components/shareWx.vue";
import pop from "@/components/pop.vue";
import PopSuccess from "../components/popSuccess.vue";
import toper from "../components/toper.vue";
import PopUserConfirm from "../components/popUserConfirm.vue";
export default {
  components: {
    shareWx,
    pop,
    PopSuccess,
    toper,
    PopUserConfirm,
  },
  data() {
    return {
      banner: "",
      banner_link: "",
      name: "",
      title: "",
      title_en: "",
      propaganda: "",
      video: "",
      showShareWx: false,
      showPop: false,
      showPopSuccess: false,
      userId: "",
      userInfo: {},
      feedbackContent: "",
      saleInfo: {},
      showUserConfirm: false,
      popUserConfirmSuccess: () => {},
      exhibitionId: '',
      productType: '',
    };
  },
  mounted() {
    this.userInfo = this.$getUserInfo();
    this.userId = this.userInfo.id;
    if (this.userId) {
      this.getConfigList();
      this.getSaleInfo();
    }
    wxShare({});
  },
  methods: {
    closeUserConfirm() {
      this.showUserConfirm = false;
    },
    goWechatArticle() {
      window.open("https://mp.weixin.qq.com/s/To8N8yyF1l_CeobkM2tt9w");
    },
    callSale() {
      window.open(`tel:${this.saleInfo.phone}`);
    },
    goExhibition() {
      this.$router.push({
        name: "exhibition",
        query: {
          exhibitionId: this.exhibitionId,
          productType: this.productType,
        },
      });
    },
    closePopSuccess() {
      this.showPopSuccess = false;
    },
    popShow() {
      if (!this.$checkUserConfirm()) {
        this.showUserConfirm = true;
        this.popUserConfirmSuccess = () => {
          this.showUserConfirm = false;
          this.userInfo = this.$getUserInfo();
          this.userId = this.userInfo.id;
          this.popShow();
        };
        return;
      }
      this.showPop = true;
    },
    closePop() {
      this.showPop = false;
    },
    feedbackSubmit() {
      this.$http
        .post(
          "/sale/h5/addFeedback",
          {
            user_id: this.userId,
            user_name: this.userInfo.user_name,
            phone: this.userInfo.phone,
            company: this.userInfo.company,
            content: this.feedbackContent,
          },
          true
        )
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.closePop();
            this.showPopSuccess = true;
          }
        });
    },
    getSaleInfo() {
      this.$http
        .get(
          "/sale/h5/getSalePhone",
          {
            user_id: this.userId,
          },
          true
        )
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.saleInfo = res.data;
          }
        });
    },
    getConfigList() {
      this.$http
        .get(
          "/sale/h5/config",
          {
            user_id: this.userId,
            share_code: localStorage.getItem("share_code"),
          },
          true
        )
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.banner = res.data.banner;
            this.banner_link = res.data.banner_link;
            this.title = res.data.title;
            this.exhibitionId = res.data.id;
            this.productType = res.data.product_type;
            this.name = res.data.name;
            this.title_en = res.data.title_en;
            this.propaganda = res.data.propaganda;
            this.video = res.data.video;
          }
        });
    },
    toBanner() {
      window.open(this.banner_link, "_blank");
    },
    goMore() {
      this.$router.push({
        name: "moreCase",
        params: {
          banner: this.banner,
          banner_link: this.banner_link,
          title: this.title,
        },
      });
    },
    closeShareWx() {
      this.showShareWx = false;
    },
    ToIntention() {
      this.$http
        .get("/sale/h5/priceDetail", { user_id: this.userId })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.id) {
              this.$router.push({
                name: "intention",
                query: {
                  userId: this.userId, //this.$route.query.id,
                },
              });
            } else {
              this.$toast.fail("暂无报价，请联系专属商务");
            }
          }
        });
    },
    shareFriend() {
      console.log("点击分享");
      this.showShareWx = true;
    },
    download() {
      this.$toast.fail("敬请期待");
    },
    toBuild() {
      if (!this.$checkUserConfirm()) {
        this.showUserConfirm = true;
        this.popUserConfirmSuccess = () => {
          this.showUserConfirm = false;
          this.userInfo = this.$getUserInfo();
          this.userId = this.userInfo.id;
          this.toBuild();
        };
        return;
      }
      sessionStorage.clear();
      let id = this.$route.query.id
        ? this.$route.query.id
        : localStorage.getItem("id");
      this.$router.push({
        name: "order",
        query: {
          userId: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f2f2f2;
}
.page {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  overflow-x: hidden;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .topContent {
      width: 690px;
      margin: 0 auto;
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      .moreLink {
        width: 142px;
        height: 44px;
        line-height: 44px;
        color: #d6191f;
        border-radius: 16px 16px 16px 16px;
        opacity: 1;
        border: 2px solid #d6191f;
        font-size: 28px;
      }
      .downloadLink {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 542px;
        height: 80px;
        line-height: 80px;
        background: #ffffff;
        border-radius: 12px 12px 12px 12px;
        box-sizing: border-box;
        opacity: 1;
        .downloadText {
          font-size: 28px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
        .downloadBtn {
          width: 108px;
          height: 40px;
          line-height: 40px;
          background: #d6191f;
          border-radius: 16px 16px 16px 16px;
          opacity: 1;
          font-size: 28px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #fefefe;
          margin-left: 24px;
        }
      }
    }
    .desBanner {
      position: relative;
      width: 690px;
      height: 486px;
      box-sizing: border-box;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
      .desSrc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .desFooter {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        bottom: 0;
        width: 100%;
        height: 104px;
        background: #999999;
        box-sizing: border-box;
        .leftDes {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .footerTitle {
            font-size: 32px;
            font-weight: 400;
            color: #ffffff;
            text-align: left;
          }
          .englishDes {
            font-size: 20px;
            font-weight: 200;
            color: #ffffff;
            opacity: 42%;
            text-align: left;
          }
        }
        .rightDes {
          width: 40px;
          height: 40px;
        }
      }
    }
    .middleBanner {
      margin-top: 20px;
      width: 690px;
      height: 270px;
      box-sizing: border-box;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
      .middleImg {
        width: 100%;
      }
    }
    .footerBanner {
      margin-top: 20px;
      width: 690px;
      height: 270px;
      box-sizing: border-box;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
      .footerImg {
        width: 100%;
      }
    }
    .footerBtn {
      margin-top: 70px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .offer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 182px;
        height: 58px;
        line-height: 58px;
        background: linear-gradient(
          180deg,
          rgba(224, 31, 36, 0) 0%,
          rgba(224, 31, 36, 0.5) 100%
        );
        border-radius: 12px 12px 12px 12px;
        margin-right: 46px;
        .inOffer {
          width: 170px;
          height: 50px;
          background: #e01f24;
          border-radius: 12px 12px 12px 12px;
          opacity: 1;
          font-size: 24px;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .build {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 136px;
        height: 136px;
        background: linear-gradient(
          180deg,
          rgba(224, 31, 36, 0) 0%,
          rgba(224, 31, 36, 0.5) 100%
        );
        border-radius: 50%;
        padding: 8px;
        opacity: 1;
        padding: 0 30px;
        box-sizing: border-box;
        .buildWord {
          flex-shrink: 0;
          width: 120px;
          height: 120px;
          background: rgba(224, 31, 36, 1);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          p {
            font-size: 24px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .exclusive {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 182px;
        height: 58px;
        line-height: 58px;
        background: linear-gradient(
          180deg,
          rgba(224, 31, 36, 0) 0%,
          rgba(224, 31, 36, 0.5) 100%
        );
        border-radius: 12px 12px 12px 12px;
        margin-left: 46px;
        .inexclusive {
          width: 170px;
          height: 50px;
          background: #e01f24;
          border-radius: 12px 12px 12px 12px;
          opacity: 1;
          font-size: 24px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .message {
      margin-top: 14px;
      margin-bottom: 42px;
      width: 96px;
      height: 34px;
      font-size: 24px;
      font-weight: 400;
      color: #666666;
      border-bottom: 1px solid #666;
    }
  }
}
.pop-info {
  margin: 0 42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .info-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 98px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 541px;
      right: 0;
      background: #cccccc;
      height: 1px;
    }
    .left {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      img {
        width: 37px;
        margin-right: 16px;
      }
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      input {
        font-size: 28px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        border: 0;
        width: 100%;
        height: 100%;
        text-align: right;
        margin: 0;
        outline: none;
        background: transparent;
      }
      span {
        font-size: 28px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .more-item {
    margin: 20px 0;
    textarea {
      width: 100%;
      height: 304px;
      border-radius: 16px 16px 16px 16px;
      border: 2px solid #6b6b6b;
      box-sizing: border-box;
      padding: 20px;
    }
  }
}
@media screen and (orientation: landscape) {
  .page {
    min-width: 750px !important;
    max-width: 850px !important;
    margin: auto !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
  }
}
</style>

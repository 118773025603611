<template>
  <div class="page">
    <div class="navHead">
      <div class="nav">
        <div class="navName">意向订单表</div>
        <img
          :src="$ossRes('/common/arrow.png')"
          alt=""
          class="shareIcon"
          @click="back"
        />
      </div>
    </div>
    <div class="content">
      <div class="desLine">
        <div class="line"></div>
        <p class="lineText">套餐内功能</p>
      </div>
      <div class="comboList">
        <!-- <div v-for="(item, index) in comboList" :key="index" class="combo-item" @click="choseCom(item, index)"> -->
        <div v-for="(item, index) in comboList" :key="index" class="combo-item">
          <!-- <div v-show="item.checked" class="combo-item-checked">
            <van-icon name="success" class="cic-su" />
          </div> -->
          <img :src="item.img" alt="" class="comboImg" />
          <p class="comboContent">{{ item.title }}</p>
        </div>
      </div>
      <div class="footer">
        <div class="lastStep" @click="back">上一步</div>
        <div class="nextStep" @click="next">下一步</div>
      </div>
    </div>
  </div>
</template>
    <script>
import { get, post } from "@/assets/js/http.js";
export default {
  data() {
    return {
      comboList: [],
    };
  },
  created() {
    sessionStorage.removeItem('function_outside')
    this.getComboList();
  },
  watch: {},
  methods: {
    choseCom(item, index){
      this.comboList[index].checked = item.checked ? 0 : 1
    },
    back() {
      this.$router.back();
    },
    next(){
      let arr = []
      if(this.comboList.length > 0){
        this.comboList.map(item => {
          if(item.checked)
            arr.push(item)
        })
      }
      if(arr.length < 1){
        this.$toast.fail("请选择套餐");
        return
      }
      sessionStorage.removeItem('function_outside')
      sessionStorage.setItem('function_inside', JSON.stringify(this.comboList))
      this.$router.push({
        name: "outsideCombo",
        query: {
          userId: this.$route.query.userId,
          type: this.$route.query.type,
          num: this.$route.query.num,
        },
      })
    },
    getComboList() {
      let function_inside = sessionStorage.getItem('function_inside')
      if(function_inside){
        this.comboList = JSON.parse(sessionStorage.getItem('function_inside'))
      } else
        this.$http.get(
          "/sale/price/getPackageTemplate",
          {
            type: this.$route.query.type,
            num: this.$route.query.num,
          }
        ).then((res) => {
          if (res.code === 200 && res.data) {
            // if(res.data.function_inside.length > 0){
            //   res.data.function_inside.map(item => {
            //     item.checked = 0
            //   })
            // }
            this.comboList = res.data.function_inside;
          }
        });
    },
  },
};
</script>
    
<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  // padding-bottom: 120px;
  background-color: #f2f2f2;
  // overflow-x: hidden;
  .navHead {
    position: relative;
    // background-image: url("~@/assets/image/navHead.png");
    background-image:url("@{PublicEnv}/common/navHead.png");
    background-size: 100% 100%;
    width: 100%;
    height: 96px;
    z-index: 10;
    .nav {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 48px;
      box-sizing: border-box;
      height: 96px;
      line-height: 96px;
      text-align: center;
      .navName {
        font-size: 36px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .shareIcon {
        position: absolute;
        left: 48px;
        bottom: 23px;
        width: 50px;
        height: 54px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 15;
    margin-bottom: 50px;
    .desLine {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 690px;
      height: 40px;
      line-height: 40px;
      margin-top: 22px;
      margin-bottom: 12px;
      .line {
        width: 4px;
        height: 40px;
        margin-right: 16px;
        background-color: #d61a20;
      }
      .lineText {
        font-size: 28px;
        font-weight: 400;
        color: #d61a20;
      }
    }
    .comboList {
      width: 700px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .combo-item {
        position: relative;
        width: 220px;
        height: 218px;
        margin-bottom: 20px;
        .combo-item-checked{
          position: absolute;
          width: 220px;
          height: 218px;
          top: 0;
          left: 0;
          right: 0;
          z-index: 2;
          // background: rgba(255,255,255,.5);
          background: rgba(0,0,0,.5);
          .cic-su{
            font-size: 80px;
            color: #fff;
            line-height: 218px;
          }
        }
        .comboImg {
          width: 100%;
          height: 100%;
        }
        .comboContent {
          position: absolute;
          bottom: 0;
          width: 200px;
          padding: 0 10px;
          height: 41px;
          line-height: 41px;
          background: rgba(51, 51, 51, 0.4);
          border-radius: 0px 0px 12px 12px;
          opacity: 1;
          font-size: 24px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          text-shadow: 2px 0px 2px rgba(0, 0, 0, 0.4);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
    .footer {
      width: 100%;
      height: 96px;
      line-height: 96px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 48px;
      font-size: 32px;
      font-weight: 400;
      color: #ffffff;
      .lastStep {
        width: 320px;
        height: 96px;
        background: #cccccc;
        border-radius: 48px 48px 48px 48px;
        opacity: 1;
        margin-right: 14px;
      }
      .nextStep {
        width: 320px;
        height: 96px;
        background: #d61a20;
        border-radius: 48px 48px 48px 48px;
        opacity: 1;
      }
    }
  }
}
@media screen and (orientation: landscape) {
  .page {
    min-width: 750px !important;
    max-width: 850px !important;
    margin: auto !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import moreCase from '../views/moreCase.vue'
import intention from '../views/intention.vue'
import order from '../views/order/index'
import onCombo from '../views/order/onCombo'
import outsideCombo from '../views/order/outsideCombo'
import setOrder from '../views/order/setOrder'
import orderIntention from '../views/order/orderIntention'
import sdk  from '../views/text/sdk'
import authority  from '../views/text/authority'
import privacyPolicy  from '../views/text/privacyPolicy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/exhibition',
    name: 'exhibition',
    component: () => import(`@/views/exhibition`)
  },
  {
    path: '/moreCase',
    name: 'moreCase',
    component: moreCase
  },
  {
    path: '/intention',
    name: 'intention',
    component: intention
  },
  {
    path: '/intentionQandA',
    name: 'intentionQandA',
    component: () => import(`@/views/intentionQandA`)
  },
  {
    path: '/intentionQandADetail',
    name: 'intentionQandADetail',
    component: () => import(`@/views/intentionQandADetail`)
  },
  {
    path: '/order',
    name: 'order',
    component: order
  },
  {
    path: '/onCombo',
    name: 'onCombo',
    component: onCombo
  },
  {
    path: '/outsideCombo',
    name: 'outsideCombo',
    component: outsideCombo
  },
  {
    path: '/setOrder',
    name: 'setOrder',
    component: setOrder
  },
  {
    path: '/orderIntention',
    name: 'orderIntention',
    component: orderIntention
  },
  {
    path: '/sdk',
    name: 'sdk',
    component: sdk
  },
  {
    path: '/authority',
    name: 'authority',
    component: authority
  },
  {
    path: '/pripolicy',
    name: 'privacypolicy',
    component: privacyPolicy
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router

<template>
  <pop
    :show="show"
    @close="close"
    :title="'资料确认'"
    @submit="submit"
  >
    <div class="pop-info">
      <div class="info-item">
        <div class="left">
          <img :src="$ossRes('/common/pop-name.png')" />
          <span>姓名</span>
        </div>
        <div class="right">
          <input v-model="name" />
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <img :src="$ossRes('/common/pop-company.png')" />
          <span>公司/单位</span>
        </div>
        <div class="right">
          <input v-model="company" />
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <img :src="$ossRes('/common/jobtype.png')" />
          <span>职位</span>
        </div>
        <div class="right">
          <input v-model="position" />
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <img :src="$ossRes('/common/pop-tel.png')" />
          <span>手机号</span>
        </div>
        <div class="right">
          <input v-model="phone" />
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <img :src="$ossRes('/common/send.png')" />
          <span>验证码</span>
        </div>
        <div class="right">
          <input v-model="verifyCode" placeholder="请输入验证码"/>
        </div>
        <div :class="`more ${isCounting?'counting':''}`" @click.stop.prevent="countDown">
          <span>{{ isCounting?`${count}s后重新获取`:'点击获取' }}</span>
        </div>
      </div>
    </div>
  </pop>
</template>

<script>
import pop from "@/components/pop.vue";
import { checkPhone } from "@/assets/js/utils.js";
export default {
  components: {
    pop,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler: function (newVal) {
        if (newVal) {
          const userInfo = this.$getUserInfo();
          this.name = userInfo.user_name;
          this.phone = userInfo.phone;
          this.company = userInfo.company;
          this.position = userInfo.position;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      name: "",
      phone: "",
      verifyCode: "",
      company: "",
      position: "",
      count: 60,
      isCounting: false,
      isSubmiting: false,
    };
  },
  methods: {
    countDown(){
      if(!checkPhone(this.phone)){
        this.$toast.fail('请输入正确的手机号码')
        return;
      }
      if(!this.isCounting){
        this.$http.post('/sale/login/sendMsg', {
          phone: this.phone
        }).then((res)=>{
          if(res.code === 200){
            this.$toast.success(res.msg)
            this.isCounting = true;
            this.startCount();
          }else{
            this.$toast.fail(res.msg)
          }
        })
      }
    },
    startCount(){
      if(this.count > 0){
        setTimeout(() => {
          this.count = this.count - 1;
          this.startCount();
        }, 1000);
      }else{
        this.isCounting = false;
        this.count = 60;
      }
    },
    submit() {
      if(!this.name){
        this.$toast.fail('请输入姓名')
        return;
      }
      if(!this.company){
        this.$toast.fail('请输入公司/单位')
        return;
      }
      if(!this.position){
        this.$toast.fail('请输入职位')
        return;
      }
      if(!checkPhone(this.phone)){
        this.$toast.fail('请输入正确的手机号码')
        return;
      }
      if(!this.verifyCode){
        this.$toast.fail('请输入验证码')
        return;
      }
      if(this.isSubmiting){
        return;
      }
      this.isSubmiting = true;
      this.$http.post('/sale/h5/userValidate', {
          phone: this.phone,
          user_name: this.name,
          code: this.verifyCode,
          company: this.company,
          position: this.position,
        }).then((res)=>{
          this.isSubmiting = false;
          if(res.code === 200){
            this.$toast.success('资料确认成功')
            Object.keys(res.data).forEach((i) => {
              localStorage.setItem(i, res.data[i]);
            });
            setTimeout(() => {
              this.$emit('success');
            }, 1000);
          }else{
            this.$toast.fail(res.msg)
          }
        })
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.pop-info {
  margin: 0 42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .info-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 98px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 541px;
      right: 0;
      background: #cccccc;
      height: 1px;
    }
    &:last-child {
      margin-bottom: 20px;
    }
    .left {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      img {
        width: 37px;
        margin-right: 16px;
      }
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      input {
        font-size: 28px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        border: 0;
        width: 100%;
        height: 100%;
        text-align: right;
        margin: 0;
        outline: none;
        background: transparent;
      }
      span {
        font-size: 28px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
    .more {
      box-sizing: border-box;
      padding: 0 20px;
      height: 50px;
      flex-shrink: 0;
      background: linear-gradient(152deg, #ca020f 0%, #e01f24 50%, #ffbaba 100%);
      border-radius: 5.333333vw;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
      margin-left: 10px;
      span {
        font-size: 26px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      &.counting {
        background: #6b6b6b;
      }
    }
  }
  .more-item {
    margin: 20px 0;
    textarea {
      width: 100%;
      height: 304px;
      border-radius: 16px 16px 16px 16px;
      border: 2px solid #6b6b6b;
      box-sizing: border-box;
      padding: 20px;
    }
  }
}
</style>

/**
 * 是否为微信环境
 */
export const isWechat = () => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') return true; 
    return false;
};

/**
 * 是否为小程序环境
 */
export const isMini = (url) => {
    if (isWechat()) {
      wx.miniProgram.getEnv((res)=>{
        if (res.miniprogram) {
          // wx.miniProgram.postMessage({ data: {url: encodeURIComponent(location.href)} })
          wx.miniProgram.postMessage({ data: {url: url }})
        } else return false;
      })
    }; 
    return false;
}

/**
 * 获取url参数
 */
export const getUrlParam = (name) => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`); // 构造一个含有目标参数的正则表达式对象
    const r = window.location.search.substr(1).match(reg); // 匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; // 返回参数值
}

/**
 * 获取url所有参数
 */
export function getUrlParamsAll(url) {
  const urlSearchParams = new URLSearchParams(url.split('?')[1])
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}

/**
 * 是否为路由白名单 全局
 * @param {路由name} rname
 * @returns
 */
export const isWhite = ( rname ) => {
    const whiteRoutes = [];
    return whiteRoutes.includes( rname );
}


/**
 * 是否为路由白名单地址  供应商模块、答题模块
 * @returns
 */
 export const isWhitePath = () => {
    const pathname = window.location.href;
    return pathname.startsWith(`${process.env.VUE_APP_DOMAIN + process.env.VUE_APP_SUB_PATH}/login`);
};
export const checkPhone = (v)=>{
  return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(v);
}
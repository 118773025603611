<template>
  <div>
    <div class="page">
      <van-popup
        v-model="show"
        :close-on-click-overlay="false"
        :lock-scroll="false"
        style="border-radius: 16px"
      >
        <div class="pop">
          <div class="head">
            <span>{{ title }}</span>
            <div class="close" @click="close"></div>
          </div>
          <div class="infomation">
            <slot></slot>
          </div>
          <div class="footer">
            <div class="submit" @click="submit">
              <span>提交</span>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      comments: "",
    };
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less">
.van-popup {
  background-color: transparent;
  overflow: hidden;
}
</style>
<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  .pop {
    width: 686px;
    box-sizing: border-box;
    padding-bottom: 68px;
    background: #fcfcfc;
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.102);
    border-radius: 16px;
    overflow: hidden;
    .head {
      position: relative;
      width: 100%;
      height: 90px;
      background: linear-gradient(
        152deg,
        #7e0009 0%,
        #e01f24 50%,
        #ffbaba 100%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 36px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .close {
        position: absolute;
        top: 28px;
        left: 44px;
        width: 34px;
        height: 34px;
        background: url("@{PublicEnv}/common/close.png");
        background-size: 100% 100%;
      }
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .submit {
        width: 490px;
        height: 80px;
        background: #d6191f;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 32px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>

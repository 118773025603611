<template>
  <div class="header">
    <div class="nav">
      <img
        class="back"
        v-if="back"
        :src="$ossRes('/common/back.png')"
        alt=""
        @click="doBack"
      />
      <div class="navName">{{ title }}</div>
      <img
        class="share"
        v-if="share"
        :src="$ossRes('/common/shareIcon.png')"
        alt=""
        @click="doShare"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    share: Boolean,
    back: Boolean,
    backFunc: Boolean,
  },
  data() {
    return {
      comments: "",
    };
  },
  methods: {
    doShare() {
      this.$emit("doShare");
    },
    doBack() {
      if (this.backFunc) {
        this.$emit("doBack");
      } else {
        // this.$router.back();
        this.$router.replace({
          name: 'Home'
        })
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 96px;
  background: url("@{PublicEnv}/common/navHead.png");
  background-size: 100%;
  .nav {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .navName {
      font-size: 36px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    .back {
      position: absolute;
      left: 48px;
      width: 50px;
      height: 54px;
    }
    .share {
      position: absolute;
      right: 48px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>

<template>
  <div id="app" v-if="appShow">
    <router-view/>
  </div>
</template>
<script>
import { doAuth } from "@/assets/js/wechatAuth";
export default {
  data() {
    return {
      appShow: false,
    };
  },
  created() {
    const _this = this;
    doAuth().then((pass) => {
      if (pass) {
        _this.appShow = true;
      } else {
        _this.appShow = true;
      }
    });
  },
}
</script>

<style lang="less">
.van-toast{
  // width: 250px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
body, html{
  height: 100%;
}
p{
  padding: 0;
  margin: 0;
}
</style>

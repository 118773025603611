<template>
  <div>
    <div class="page">
      <van-popup
        v-model="show"
        :close-on-click-overlay="false"
        :lock-scroll="false"
        style="border-radius: 16px"
      >
        <div class="toast" v-if="showToast">
          <div class="head">
            {{ msg }}
            <div class="close" @click="close"></div>
          </div>
          <div class="infomation">
            <div class="styleInfo">
              <!-- <img src="~@/assets/image/name.png" alt="" class="infoImg" /> -->
              <img :src="$ossRes('/common/name.png')" alt="" class="infoImg" />
              <div class="infoInput">
                <div>姓名</div>
                <div>{{ feedDate.user_name }}</div>
              </div>
            </div>
            <div class="styleInfo">
              <!-- <img src="~@/assets/image/tel.png" alt="" class="infoImg" /> -->
              <img :src="$ossRes('/common/tel.png')" alt="" class="infoImg" />
              <div class="infoInput">
                <div>手机号</div>
                <div>{{ feedDate.phone }}</div>
                <!-- <input type="tel" v-model="feedDate.phone"> -->
              </div>
            </div>
            <div class="styleInfo">
              <!-- <img src="~@/assets/image/company.png" alt="" class="infoImg" /> -->
              <img :src="$ossRes('/common/company.png')" alt="" class="infoImg" />
              <div class="infoInput">
                <div>公司/单位</div>
                <div>{{ feedDate.company }}</div>
                <!-- <input v-model="feedDate.company" disabled> -->
              </div>
            </div>
            <div class="styleFooter">
              <textarea v-model="comments" class="barrage_inp"></textarea>
            </div>
            <div class="footer">
              <div class="adviseSubmit" @click="feedBack">提交</div>
            </div>
          </div>
        </div>
        <div class="success" v-if="showSuccess">
          <!-- <div class="successHead"></div> -->
          <div class="successTitle">提交成功</div>
          <div class="successText">将有专属商务与您联系，请保持手机畅通</div>
          <div class="successBtn">
            <div class="sureClick" @click="close">确定</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
  
<script>
import { post } from "@/assets/js/http.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    feedDate: {
      type: Object,
      default: {},
    },
    msg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      comments: "",
      showToast: true,
      showSuccess: false,
    };
  },
  methods: {
    close() {
      this.$emit("closeFeed");
    },
    feedBack() {
      if (!this.comments) {
        this.$toast.fail("请输入反馈内容");
        return;
      } else {
        post(
          "/sale/h5/addFeedback",
          {
            user_id: this.feedDate.user_id,
            content: this.comments,
          },
          true
        ).then((res) => {
          if (res.code === 200 && res.data) {
            this.showToast = false;
            this.showSuccess = true;
          }
        });
      }
    },
  },
};
</script>
  <style lang="less">
.van-popup {
  background-color: transparent;
  overflow: hidden;
}
</style>
  <style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  .toast {
    width: 686px;
    height: 884px;
    background: #fcfcfc;
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.102);
    border-radius: 16px;
    opacity: 1;
    .head {
      position: relative;
      width: 686px;
      height: 90px;
      line-height: 90px;
      background: linear-gradient(
        152deg,
        #7e0009 0%,
        #e01f24 50%,
        #ffbaba 100%
      );
      border-radius: 12px 12px 0px 0px;
      opacity: 1;
      font-size: 36px;
      font-weight: 400;
      color: #ffffff;
      .close {
        position: absolute;
        top: 28px;
        left: 44px;
        width: 34px;
        height: 34px;
        // background-image: url("~@/assets/image/close.png");
        background: url("@{PublicEnv}/common/close.png");
        background-size: 100% 100%;
      }
    }
    .infomation {
      width: 686px;
      background: #fcfcfc;
      border-radius: 16px;
      opacity: 1;
      margin-top: 34px;
      padding: 0 43px;
      box-sizing: border-box;
      .styleInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 32px;
        .infoImg {
          width: 40px;
          height: 40px;
        }
        .infoInput {
          display: flex;
          // flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 547px;
          height: 72px;
          line-height: 45px;
          font-size: 28px;
          font-weight: 400;
          color: #333333;
          border-bottom: 1px solid #cccccc;
          input{
            border: none;
            background: transparent;
          }
        }
      }
      .styleFooter {
        display: flex;
        justify-content: center;
        align-content: center;
        opacity: 0.6;
        width: 100%;
        height: 304px;
        background: #fcfcfc;
        border-radius: 16px;
        border: 2px solid #6b6b6b;
        margin-top: 20px;
        padding: 30px;
        box-sizing: border-box;
        overflow: scroll;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .barrage_inp {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
      .footer {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        box-sizing: border-box;
        margin-top: 20px;
        .adviseSubmit {
          width: 490px;
          height: 80px;
          line-height: 80px;
          background: #d6191f;
          border-radius: 40px 40px 40px 40px;
          opacity: 1;
          border: 1px solid #d6191f;
          font-size: 32px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .success {
    width: 686px;
    height: 568px;
    box-sizing: border-box;
    position: relative;
      background: url("@{PublicEnv}/common/success-head.png");
      background-size: 100% 100%;
    // .successHead {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   width: 686px;
    //   height: 568px;
    //   // background-image: url("~@/assets/image/successHead.png");
    //   background: url("@{PublicEnv}/common/success-head.png");
    //   background-size: 100% 100%;
    // }
    .successTitle {
      width: 100%;
      height: 56px;
      font-size: 40px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #d6161c;
      line-height: 56px;
      text-align: center;
      padding-top: 214px;
      margin-bottom: 20px;
    }
    .successText {
      width: 100%;
      height: 44px;
      font-size: 32px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
      text-align: center;
    }
    .successBtn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 62px;
      .sureClick {
        width: 554px;
        height: 96px;
        line-height: 96px;
        background: #d6161c;
        box-shadow: 0px 0px 40px 0px rgba(83, 83, 83, 0.302);
        border-radius: 48px 48px 48px 48px;
        opacity: 1;
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>
  